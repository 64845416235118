import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ComingSoonPage from './pages/ComingSoonPage';
import VideoListPage from './pages/VideoListPage';
import VideoDetailPage from './pages/VideoDetailPage';
import DashboardPage from './pages/DashboardPage';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<ComingSoonPage />} />
          <Route path="/imprint" element={<TermsOfService />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          {/* <Route path="/landingpage" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} />} />
          <Route path="/videos" element={<ProtectedRoute element={<VideoListPage />} />} />
          <Route path="/videos/:id" element={<ProtectedRoute element={<VideoDetailPage />} />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
