import React from 'react';

function TermsOfService() {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Imprint/Impressum</h1>
            <p><span class="s2">Wir freuen uns &uuml;ber Ihr Interesse an der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy. Der Schutz personenbezogener Daten ist f&uuml;r uns besonders wichtig, und die Nutzung unserer Website ist in der Regel ohne die Angabe solcher Daten m&ouml;glich. F&uuml;r spezielle Services kann jedoch die Erfassung und Verarbeitung von Daten erforderlich werden. In F&auml;llen, wo keine gesetzliche Grundlage f&uuml;r diese Verarbeitung vorliegt, holen wir stets das Einverst&auml;ndnis der betroffenen Person ein.</span></p>
<p><span class="s2">Die Verarbeitung personenbezogener Daten, wie Name, Adresse, E-Mail oder Telefonnummer, erfolgt bei der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy gem&auml;&szlig; der DSGVO und den geltenden nationalen Datenschutzgesetzen. Diese Datenschutzerkl&auml;rung dient dazu, die &Ouml;ffentlichkeit &uuml;ber die Erhebung, Nutzung und Verarbeitung solcher Daten aufzukl&auml;ren, einschlie&szlig;lich der spezifischen Art, des Umfangs und der Zwecke. Au&szlig;erdem informiert sie betroffene Personen &uuml;ber ihre Rechte in Bezug auf ihre Daten.</span></p>
<p><span class="s2">Die </span><span class="s2">Concrete</span><span class="s2"> Gold Academy nutzt umfassende technische und organisatorische Ma&szlig;nahmen, um einen hohen Schutz der &uuml;ber die Website verarbeiteten personenbezogenen Daten sicherzustellen. Da eine absolut sichere &Uuml;bertragung im Internet nicht garantiert werden kann, k&ouml;nnen betroffene Personen Daten alternativ, etwa telefonisch, &uuml;bermitteln.</span></p>
<p>&nbsp;</p>
<p><span class="s2">1. Begriffsbestimmungen</span></p>
<p><span class="s2">Die Datenschutzerkl&auml;rung der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy definiert zentrale Begriffe der DSGVO zur Klarstellung f&uuml;r Kunden und &Ouml;ffentlichkeit. Die relevanten Definitionen umfassen unter anderem </span><span class="s3">personenbezogene Daten</span><span class="s2">, die </span><span class="s3">betroffene Person</span><span class="s2">, den </span><span class="s3">Verantwortlichen</span><span class="s2">, die </span><span class="s3">Verarbeitung</span><span class="s2"> und </span><span class="s3">Pseudonymisierung</span><span class="s2">. Auch Begriffe wie </span><span class="s3">Profiling</span><span class="s2">, </span><span class="s3">Auftragsverarbeiter</span><span class="s2">, </span><span class="s3">Empf&auml;nger</span><span class="s2"> und </span><span class="s3">Einwilligung</span><span class="s2"> werden erkl&auml;rt, um das Vorgehen der Academy bei Datenschutzbelangen transparent zu machen. Jede Datenverarbeitung erfolgt auf Basis gesetzlicher Vorschriften und Einwilligung der betroffenen Person, wo erforderlich.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">a)&nbsp;&nbsp;&nbsp; personenbezogene Daten</span></p>
<p><span class="s2">Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare nat&uuml;rliche Person (im Folgenden &bdquo;betroffene Person&ldquo;) beziehen. Als identifizierbar wird eine nat&uuml;rliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identit&auml;t dieser nat&uuml;rlichen Person sind, identifiziert werden kann.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">b)&nbsp;&nbsp;&nbsp; betroffene Person</span></p>
<p><span class="s2">Betroffene Person ist jede identifizierte oder identifizierbare nat&uuml;rliche Person, deren personenbezogene Daten von dem f&uuml;r die Verarbeitung Verantwortlichen verarbeitet werden.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">c)&nbsp;&nbsp;&nbsp; Verarbeitung</span></p>
<p><span class="s2">Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgef&uuml;hrte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Ver&auml;nderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch &Uuml;bermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verkn&uuml;pfung, die Einschr&auml;nkung, das L&ouml;schen oder die Vernichtung.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">d)&nbsp;&nbsp;&nbsp; Einschr&auml;nkung der Verarbeitung</span></p>
<p><span class="s2">Einschr&auml;nkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre k&uuml;nftige Verarbeitung einzuschr&auml;nken.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">e)&nbsp;&nbsp;&nbsp; </span><span class="s2">Profiling</span></p>
<p><span class="s2">Profiling</span><span class="s2"> ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte pers&ouml;nliche Aspekte, die sich auf eine nat&uuml;rliche Person beziehen, zu bewerten, insbesondere, um Aspekte bez&uuml;glich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, pers&ouml;nlicher Vorlieben, Interessen, Zuverl&auml;ssigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser nat&uuml;rlichen Person zu analysieren oder vorherzusagen.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">f)&nbsp;&nbsp;&nbsp;&nbsp; Pseudonymisierung</span></p>
<p><span class="s2">Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung zus&auml;tzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden k&ouml;nnen, sofern diese zus&auml;tzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Ma&szlig;nahmen unterliegen, die gew&auml;hrleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren nat&uuml;rlichen Person zugewiesen werden.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">g)&nbsp;&nbsp;&nbsp; Verantwortlicher oder f&uuml;r die Verarbeitung Verantwortlicher</span>&nbsp;</p>
<p><span class="s2">Verantwortlicher oder f&uuml;r die Verarbeitung Verantwortlicher ist die nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise k&ouml;nnen die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">h)&nbsp;&nbsp;&nbsp; Auftragsverarbeiter</span>&nbsp;</p>
<p><span class="s2">Auftragsverarbeiter ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Empf&auml;nger</span>&nbsp;</p>
<p><span class="s2">Empf&auml;nger ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt werden, unabh&auml;ngig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Beh&ouml;rden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten m&ouml;glicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empf&auml;nger.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">j)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dritter</span></p>
<p><span class="s2">Dritter ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle au&szlig;er der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.</span></p>
<p><span class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="s2">k)&nbsp;&nbsp;&nbsp; Einwilligung</span></p>
<p><span class="s2">Einwilligung ist jede von der betroffenen Person freiwillig f&uuml;r den bestimmten Fall in informierter Weise und unmissverst&auml;ndlich abgegebene Willensbekundung in Form einer Erkl&auml;rung oder einer </span><span class="s2">sonstigen eindeutigen best&auml;tigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.</span></p>
<p>&nbsp;</p>
<p><span class="s2">2. Name und Anschrift des f&uuml;r die Verarbeitung Verantwortlichen</span></p>
<p><span class="s2">Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europ&auml;ischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist der:</span></p>
<p><span class="s2">Concrete</span><span class="s2"> Gold Academy</span></p>
<p><span class="s2">Loui Sera Einzelunternehmen</span></p>
<p><span class="s2">Rabet 22</span></p>
<p><span class="s2">04315 Leipzig</span></p>
<p><span class="s2">Deutschland</span></p>
<p><span class="s2">Tel.: </span><span class="s2">+49 1511 2019526</span></p>
<p><span class="s2">E-Mail: </span><span class="s2">contact@cg-a</span><span class="s2">cademy.online</span></p>
<p><span class="s2">Website: </span><span class="s2">www.cg-academy.online</span></p>
<p>&nbsp;</p>
<p><span class="s2">3. </span><span class="s2">Datenschutzkoordinator</span></p>
<p><span class="s2">Der</span><span class="s2"> Date</span><span class="s2">nschutzkoordinator</span><span class="s2"> des f&uuml;r die Verarbeitung Verantwortlichen ist</span><span class="s2"> erreichbar unter</span><span class="s2">:</span></p>
<p><span class="s2">Concrete</span><span class="s2"> Gold Academy</span></p>
<p><span class="s2">Loui Sera Einzelunternehmen</span></p>
<p><span class="s2">Rabet 22</span></p>
<p><span class="s2">04315 Leipzig</span></p>
<p><span class="s2">Deutschland</span></p>
<p><span class="s2">Tel.: </span><span class="s2">+49 </span><span class="s2">176</span> <span class="s2">0442536</span></p>
<p><span class="s2">E-Mail: </span><span class="s2">data-protection@cg-academy.online</span></p>
<p><span class="s2">Website: </span><span class="s2">www.cg-academy.online</span></p>
<p><span class="s2">Bei Fragen oder Anregungen zum Datenschutz k&ouml;nnen sich betroffene Personen jederzeit direkt an unseren Datenschutzkoordinator wenden.</span></p>
<p>&nbsp;</p>
<p><span class="s2">4. Erfassung von allgemeinen Daten und Informationen</span></p>
<p><span class="s2">Die Internetseite der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy erfasst bei jedem Zugriff durch Nutzer oder automatisierte Systeme verschiedene allgemeine Daten und Informationen. Dazu z&auml;hlen die verwendeten Browsertypen, das Betriebssystem, der </span><span class="s2">Referrer</span><span class="s2">, die aufgerufenen Unterseiten, das Zugriffsdatum und die Uhrzeit, die IP-Adresse sowie der Internet-Service-Provider. Diese Daten werden in Server-Logfiles gespeichert und erm&ouml;glichen es der Academy, die Inhalte korrekt </span><span class="s2">auszuliefern, die Webseite zu optimieren und die Sicherheit ihrer Systeme zu gew&auml;hrleisten. Es werden keine R&uuml;ckschl&uuml;sse auf einzelne Personen gezogen; die anonymisierten Daten werden getrennt von personenbezogenen Informationen gespeichert, um ein hohes Schutzniveau zu garantieren.</span></p>
<p>&nbsp;</p>
<p><span class="s2">5. Registrierung auf unserer Website</span></p>
<p><span class="s2">Besucher unserer Website haben die M&ouml;glichkeit, sich unter Angabe pers&ouml;nlicher Daten zu registrieren. Die im Registrierungsformular abgefragten Informationen werden ausschlie&szlig;lich f&uuml;r interne Zwecke gespeichert und genutzt. Sofern erforderlich, k&ouml;nnen bestimmte Daten an externe Dienstleister, wie z. B. Paketdienstleister, &uuml;bermittelt werden, die sie ebenfalls nur f&uuml;r diese spezifischen Aufgaben im Auftrag unseres Unternehmens verwenden.</span></p>
<p><span class="s2">Zus&auml;tzlich speichern wir technische Daten wie die IP-Adresse, das Datum und die Uhrzeit der Registrierung. Diese Informationen dienen dem Schutz unseres Services und helfen, Missbrauch vorzubeugen oder im Bedarfsfall sicherheitsrelevante Ereignisse aufzukl&auml;ren. Eine Weitergabe an Dritte erfolgt grunds&auml;tzlich nur dann, wenn es gesetzlich vorgeschrieben ist oder der Strafverfolgung dient.</span></p>
<p><span class="s2">Die freiwillige Registrierung erm&ouml;glicht es uns, den registrierten Nutzern spezielle Inhalte oder Dienstleistungen anzubieten, die nur f&uuml;r Mitglieder zug&auml;nglich sind. Jeder registrierte Nutzer hat die M&ouml;glichkeit, seine Daten jederzeit zu aktualisieren oder deren vollst&auml;ndige L&ouml;schung zu beantragen.</span></p>
<p><span class="s2">Dar&uuml;ber hinaus stehen wir jedem Nutzer auf Wunsch jederzeit zur Verf&uuml;gung, um Auskunft &uuml;ber die gespeicherten Daten zu erteilen. Bei Bedarf passen wir diese Daten auf Anfrage an oder l&ouml;schen sie, es sei denn, gesetzliche Aufbewahrungspflichten verlangen die weitere Speicherung. Unser Team steht dabei als Ansprechpartner bereit, um Nutzeranfragen umfassend zu beantworten.</span></p>
<p>&nbsp;</p>
<p><span class="s2">6. Kontaktm&ouml;glichkeiten &uuml;ber die Website</span></p>
<p><span class="s2">Unsere Website enth&auml;lt gesetzlich vorgeschriebene Kontaktdaten, um eine schnelle Kommunikation mit der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy zu erm&ouml;glichen, einschlie&szlig;lich einer E-Mail-Adresse f&uuml;r die direkte Kontaktaufnahme. Wenn Nutzer per E-Mail oder &uuml;ber ein Kontaktformular Kontakt aufnehmen, werden ihre &uuml;bermittelten pers&ouml;nlichen Daten automatisch gespeichert. Diese Daten werden auf freiwilliger Basis erhoben und ausschlie&szlig;lich f&uuml;r die Bearbeitung der Anfrage oder f&uuml;r die R&uuml;ckmeldung genutzt. Eine Weitergabe dieser Daten an Dritte findet nicht statt.</span></p>
<p><span class="s2">7. Routinem&auml;&szlig;ige L&ouml;schung und Sperrung personenbezogener Daten</span></p>
<p><span class="s2">Personenbezogene Daten werden nur so lange gespeichert, wie es f&uuml;r den jeweiligen Zweck erforderlich ist oder wie gesetzlich vorgeschrieben. Sobald der Grund f&uuml;r die Speicherung entf&auml;llt oder eine gesetzlich vorgegebene Frist abl&auml;uft, werden die Daten routinem&auml;&szlig;ig entweder gesperrt oder gel&ouml;scht, entsprechend den geltenden Datenschutzbestimmungen und gesetzlichen Vorgaben.</span></p>
<p><span class="s2">Diese Routine gew&auml;hrleistet, dass personenbezogene Daten nur im notwendigen Umfang aufbewahrt und nach Ablauf gesetzlicher Fristen oder Wegfall des Zwecks entfernt werden.</span></p>
<p><span class="s2">8. Rechte der betroffenen Person</span></p>
<p><span class="s2">a) Recht auf Best&auml;tigung</span><span class="s2"><br /></span><span class="s2">Betroffene Personen haben das Recht, vom Verantwortlichen eine Best&auml;tigung dar&uuml;ber zu erhalten, </span><span class="s2">ob ihre personenbezogenen Daten verarbeitet werden. Um dieses Best&auml;tigungsrecht in Anspruch zu nehmen, kann sich die betroffene Person jederzeit an einen zust&auml;ndigen Mitarbeiter des Unternehmens wenden.</span></p>
<p><span class="s2">b) Recht auf Auskunft</span></p>
<p><span class="s2">Betroffene Personen haben das Recht, jederzeit unentgeltlich Auskunft &uuml;ber ihre gespeicherten personenbezogenen Daten und eine Kopie dieser Daten zu erhalten. Dazu z&auml;hlen Details wie:</span></p>
<p><span class="s2">Verarbeitungszwecke und Datenkategorien</span></p>
<p><span class="s2">Empf&auml;nger oder Kategorien von Empf&auml;ngern, besonders in Drittl&auml;ndern</span></p>
<p><span class="s2">Geplante Speicherdauer oder Kriterien zur Festlegung dieser Dauer</span></p>
<p><span class="s2">Rechte auf Berichtigung, L&ouml;schung, Verarbeitungseinschr&auml;nkung und Widerspruch</span></p>
<p><span class="s2">Beschwerderecht bei einer Aufsichtsbeh&ouml;rde</span></p>
<p><span class="s2">Informationen zur Datenquelle, falls nicht direkt erhoben</span></p>
<p><span class="s2">Bei Daten&uuml;bermittlungen ins Ausland haben Betroffene ein Recht auf Informationen zu den gew&auml;hrleisteten Schutzma&szlig;nahmen. Um das Auskunftsrecht zu nutzen, k&ouml;nnen Betroffene den Verantwortlichen kontaktieren.</span></p>
<p><span class="s2">c) Recht auf Berichtigung</span></p>
<p><span class="s2">Betroffene Personen haben das Recht, unverz&uuml;glich die Berichtigung ihrer unrichtigen personenbezogenen Daten zu verlangen. Sie k&ouml;nnen auch die Vervollst&auml;ndigung unvollst&auml;ndiger Daten fordern, unter Ber&uuml;cksichtigung des Zwecks der Verarbeitung &ndash; beispielsweise durch eine erg&auml;nzende Erkl&auml;rung.</span></p>
<p><span class="s2">Um das Berichtigungsrecht geltend zu machen, kann sich die betroffene Person jederzeit an einen zust&auml;ndigen Mitarbeiter des Verantwortlichen wenden.</span></p>
<p><span class="s2">d) Recht auf L&ouml;schung (Recht auf Vergessenwerden)</span></p>
<p><span class="s2">Jede betroffene Person hat das Recht, vom Verantwortlichen zu verlangen, ihre personenbezogenen Daten unverz&uuml;glich zu l&ouml;schen, sofern ein berechtigter Grund vorliegt und die Datenverarbeitung nicht mehr erforderlich ist. Gr&uuml;nde f&uuml;r die L&ouml;schung k&ouml;nnen u. a. sein:</span></p>
<p><span class="s2">Die Daten sind f&uuml;r den urspr&uuml;nglichen Zweck nicht mehr notwendig.</span></p>
<p><span class="s2">Die betroffene Person widerruft ihre Einwilligung zur Verarbeitung und es besteht keine andere Rechtsgrundlage.</span></p>
<p><span class="s2">Die Daten wurden unrechtm&auml;&szlig;ig verarbeitet oder m&uuml;ssen zur Erf&uuml;llung gesetzlicher Verpflichtungen gel&ouml;scht werden.</span></p>
<p><span class="s2">Die betroffene Person hat Widerspruch eingelegt und keine &uuml;berwiegenden berechtigten Gr&uuml;nde f&uuml;r die Verarbeitung bestehen.</span></p>
<p><span class="s2">Wenn die </span><span class="s2">Concrete</span><span class="s2"> Gold Academy zur L&ouml;schung verpflichtet ist und die betroffenen Daten ver&ouml;ffentlicht wurden, ergreifen wir geeignete technische und organisatorische Ma&szlig;nahmen, um weitere f&uuml;r die Datenverarbeitung Verantwortliche, die ebenfalls Zugang zu diesen Daten haben, &uuml;ber das L&ouml;schverlangen zu informieren. Die betroffene Person kann sich zur Wahrnehmung dieses Rechts </span><span class="s2">jederzeit an die zust&auml;ndigen Mitarbeiter der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy wenden, die dann die L&ouml;schung veranlassen.</span></p>
<p><span class="s2">e) Recht auf Einschr&auml;nkung der Verarbeitung</span></p>
<p><span class="s2">Jede betroffene Person hat das Recht, die Einschr&auml;nkung der Verarbeitung ihrer personenbezogenen Daten zu verlangen, wenn bestimmte Voraussetzungen erf&uuml;llt sind. Dies kann verlangt werden, wenn:</span></p>
<p><span class="s2">Die Person die Richtigkeit der Daten bestreitet und eine &Uuml;berpr&uuml;fung notwendig ist.</span></p>
<p><span class="s2">Die Datenverarbeitung unrechtm&auml;&szlig;ig ist, aber statt der L&ouml;schung nur eine eingeschr&auml;nkte Nutzung gew&uuml;nscht wird.</span></p>
<p><span class="s2">Der Verantwortliche die Daten nicht mehr ben&ouml;tigt, sie jedoch zur Rechtsverfolgung relevant sind.</span></p>
<p><span class="s2">Widerspruch gegen die Verarbeitung eingelegt wurde, und die Berechtigung gepr&uuml;ft werden muss.</span></p>
<p><span class="s2">M&ouml;chte eine betroffene Person die Einschr&auml;nkung der Verarbeitung ihrer bei der </span><span class="s2">Concrete</span><span class="s2">Gold Academy gespeicherten personenbezogenen Daten anfordern, kann sie sich direkt an einen Mitarbeiter wenden, der die Umsetzung veranlasst.</span></p>
<p><span class="s2">f) Recht auf Daten&uuml;bertragbarkeit</span></p>
<p><span class="s2">Betroffene Personen haben das Recht, die von ihnen bereitgestellten personenbezogenen Daten in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten. Sie k&ouml;nnen diese Daten auch einem anderen Verantwortlichen &uuml;bermitteln lassen, ohne dass der urspr&uuml;ngliche Verantwortliche dies behindert. Voraussetzung ist, dass die Verarbeitung auf einer Einwilligung (Art. 6 Abs. 1 a DSGVO oder Art. 9 Abs. 2 a DSGVO) oder auf einem Vertrag (Art. 6 Abs. 1 b DSGVO) beruht und automatisiert erfolgt.</span></p>
<p><span class="s2">Ferner besteht das Recht, die direkte &Uuml;bertragung der Daten an einen neuen Verantwortlichen zu verlangen, sofern dies technisch umsetzbar ist und die Rechte anderer nicht beeintr&auml;chtigt werden. M&ouml;chte eine betroffene Person dieses Recht in Anspruch nehmen, kann sie sich an einen Mitarbeiter der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy wenden, der bei der Umsetzung des &Uuml;bertragungswunsches unterst&uuml;tzt.</span></p>
<p><span class="s3">g) Recht auf Widerspruch</span></p>
<p><span class="s2">Gem&auml;&szlig; dem Europ&auml;ischen Richtlinien- und Verordnungsgeber hat jede Person das Recht, jederzeit gegen die Verarbeitung ihrer personenbezogenen Daten Widerspruch einzulegen, wenn diese Verarbeitung auf Grundlage von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt. Dieses Recht gilt ebenfalls f&uuml;r </span><span class="s2">Profiling</span><span class="s2">, das auf diesen Bestimmungen basiert. Der Widerspruch muss sich auf Gr&uuml;nde beziehen, die aus der besonderen Situation der betroffenen Person hervorgehen.</span></p>
<p><span class="s2">Im Falle eines Widerspruchs stellt die </span><span class="s2">Concrete</span><span class="s2"> Gold Academy die Verarbeitung der personenbezogenen Daten ein, es sei denn, sie kann zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Weiterverarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person &uuml;berwiegen. Eine Ausnahme gilt ebenfalls, wenn die Verarbeitung der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.</span></p>
<p><span class="s2">Widerspruch gegen Direktwerbung</span></p>
<p><span class="s2">Das Recht auf Widerspruch schlie&szlig;t auch die Verarbeitung f&uuml;r Direktwerbezwecke ein. Verarbeitet die </span><span class="s2">Concrete</span><span class="s2"> Gold Academy personenbezogene Daten zu Zwecken der Direktwerbung, so kann die betroffene Person dieser Verarbeitung jederzeit widersprechen, einschlie&szlig;lich </span><span class="s2">Profiling</span><span class="s2">, das im Zusammenhang mit Direktwerbung steht. Nach einem solchen Widerspruch werden die Daten nicht mehr f&uuml;r Werbezwecke genutzt.</span></p>
<p><span class="s2">Widerspruch bei Forschungs- und Statistikzwecken</span></p>
<p><span class="s2">Zus&auml;tzlich kann die betroffene Person der Verarbeitung ihrer Daten f&uuml;r wissenschaftliche oder historische Forschungszwecke oder f&uuml;r statistische Zwecke gem&auml;&szlig; Art. 89 Abs. 1 DS-GVO widersprechen, es sei denn, diese Verarbeitung ist zur Wahrnehmung einer Aufgabe im &ouml;ffentlichen Interesse erforderlich.</span></p>
<p><span class="s2">Um das Widerspruchsrecht auszu&uuml;ben, kann die betroffene Person sich jederzeit an einen Mitarbeiter der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy wenden. Zudem steht es der betroffenen Person frei, ihr Widerspruchsrecht auch bei Diensten der Informationsgesellschaft mittels automatisierter technischer Verfahren auszu&uuml;ben, unabh&auml;ngig von der Richtlinie 2002/58/EG.</span></p>
<p><span class="s3">h) Automatisierte Entscheidungen im Einzelfall einschlie&szlig;lich </span><span class="s3">Profiling</span></p>
<p><span class="s2">Betroffene Personen haben das Recht, keiner Entscheidung unterworfen zu werden, die ausschlie&szlig;lich auf automatisierter Verarbeitung &ndash; einschlie&szlig;lich </span><span class="s2">Profiling</span><span class="s2"> &ndash; beruht und die eine rechtliche Wirkung entfaltet oder sie erheblich beeintr&auml;chtigt. Eine solche automatisierte Entscheidung darf nur getroffen werden, wenn eine der folgenden Bedingungen erf&uuml;llt ist:</span></p>
<p><span class="s2">Die Entscheidung ist f&uuml;r den Abschluss oder die Erf&uuml;llung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich.</span></p>
<p><span class="s2">Die Entscheidung ist aufgrund von Gesetzen der EU oder der Mitgliedstaaten zul&auml;ssig, die angemessene Schutzma&szlig;nahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person vorsehen.</span></p>
<p><span class="s2">Die Entscheidung basiert auf der ausdr&uuml;cklichen Einwilligung der betroffenen Person.</span></p>
<p><span class="s2">Schutzma&szlig;nahmen und Rechte der Betroffenen</span></p>
<p><span class="s2">Falls eine automatisierte Entscheidung f&uuml;r die Vertragserf&uuml;llung erforderlich ist oder die betroffene Person ausdr&uuml;cklich zugestimmt hat, wird die </span><span class="s2">Concrete</span><span class="s2"> Gold Academy sicherstellen, dass angemessene Schutzma&szlig;nahmen greifen, um die Rechte und Interessen der betroffenen Person zu wahren. Zu diesen Schutzma&szlig;nahmen geh&ouml;ren:</span></p>
<p><span class="s3">Das Recht auf menschliches Eingreifen</span><span class="s2">: Die betroffene Person kann verlangen, dass eine Person die Entscheidung &uuml;berpr&uuml;ft.</span></p>
<p><span class="s3">Das Recht auf Darlegung des eigenen Standpunkts</span><span class="s2">: Die betroffene Person hat die M&ouml;glichkeit, ihren Standpunkt zu &auml;u&szlig;ern und ihre Perspektive darzulegen.</span></p>
<p><span class="s3">Das Recht auf Anfechtung der Entscheidung</span><span class="s2">: Die betroffene Person kann die Entscheidung hinterfragen und gegen sie Einspruch erheben.</span></p>
<p><span class="s2">Betroffene Personen, die ihre Rechte in Bezug auf automatisierte Entscheidungen wahrnehmen m&ouml;chten, k&ouml;nnen sich jederzeit an einen zust&auml;ndigen Mitarbeiter der </span><span class="s2">Concrete</span><span class="s2">Gold Academy wenden, um Unterst&uuml;tzung bei der Durchsetzung dieser Rechte zu erhalten.</span></p>
<p><span class="s2">i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</span></p>
<p><span class="s2">Jede Person, deren personenbezogene Daten verarbeitet werden, hat das Recht, eine zuvor erteilte Einwilligung zur Datenverarbeitung jederzeit zu widerrufen. Um dieses Recht auszu&uuml;ben, kann sich die betroffene Person jederzeit an einen Mitarbeiter des f&uuml;r die Datenverarbeitung Verantwortlichen wenden. Dies stellt sicher, dass individuelle Entscheidungen zur Datenverarbeitung respektiert werden.</span></p>
<p><span class="s2">9. Datenschutzbestimmungen zu Einsatz und Verwendung von Facebook</span></p>
<p><span class="s2">Die Webseite der </span><span class="s2">Concrete</span><span class="s2"> Gold Academy nutzt Facebook-Komponenten, da Facebook ein soziales Netzwerk ist, das den Nutzern erm&ouml;glicht, miteinander zu kommunizieren und Informationen auszutauschen. Nutzer k&ouml;nnen private Profile erstellen, Fotos hochladen und Freundschaftsanfragen senden, wodurch sie ihre Erfahrungen und Meinungen in einer Online-Community teilen k&ouml;nnen. Dieses Netzwerk dient als Plattform, um pers&ouml;nliche und gesch&auml;ftliche Informationen bereitzustellen und Interaktionen im virtuellen Raum zu f&ouml;rdern.</span><span class="s2">Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, USA. F&uuml;r die Verarbeitung personenbezogener Daten Verantwortlicher ist, wenn eine betroffene Person au&szlig;erhalb der USA oder Kanada lebt, die Facebook </span><span class="s2">Ireland</span><span class="s2"> Ltd., 4 Grand </span><span class="s2">Canal</span><span class="s2"> Square, Grand </span><span class="s2">Canal</span><span class="s2"> Harbour, Dublin 2, </span><span class="s2">Ireland</span><span class="s2">.</span></p>
<p><span class="s2">Bei jedem Zugriff auf eine unserer Seiten, die eine Facebook-Komponente integriert hat, wird der Internetbrowser der betroffenen Person automatisch veranlasst, Informationen an Facebook zu &uuml;bermitteln. Dies geschieht, um die jeweilige Facebook-Komponente zu laden. Wenn die betroffene Person gleichzeitig bei Facebook angemeldet ist, kann Facebook erkennen, welche spezifische Unterseite besucht wird.</span></p>
<p><span class="s2">Alle Interaktionen, wie das Klicken auf den &bdquo;Gef&auml;llt mir&ldquo;-Button oder das Abgeben eines Kommentars, werden ebenfalls dem Facebook-Konto zugeordnet. Diese Daten werden dann von Facebook gespeichert. Um eine solche &Uuml;bertragung zu vermeiden, sollte sich die betroffene Person vor dem Besuch unserer Webseite von ihrem Facebook-Konto abmelden.</span></p>
<p><span class="s2">Weitere Details zur Datenerfassung und den Datenschutzrichtlinien von Facebook sind auf deren </span><a href="https://de-de.facebook.com/about/privacy/"><span class="s4">Datenrichtlinien-Seite</span></a><span class="s2"> verf&uuml;gbar. Dort finden sich auch Informationen &uuml;ber die M&ouml;glichkeiten zur Wahrung der Privatsph&auml;re und verf&uuml;gbare Tools, um die Daten&uuml;bertragung an Facebook zu unterdr&uuml;cken.</span></p>
<p><span class="s2">10. Rechtsgrundlage der Verarbeitung</span></p>
<p><span class="s2">Gem&auml;&szlig; Art. 6 I </span><span class="s2">lit</span><span class="s2">. a DS-GVO dient diese Rechtsgrundlage unserem Unternehmen dazu, personenbezogene Daten zu verarbeiten, wenn wir daf&uuml;r die ausdr&uuml;ckliche Einwilligung der betroffenen Person einholen. Dies ist insbesondere der Fall, wenn es um spezifische Verarbeitungszwecke geht, bei denen die betroffene Person informiert wird und freiwillig zustimmt.</span></p>
<p><span class="s2">In F&auml;llen, in denen die Verarbeitung personenbezogener Daten notwendig ist, um einen Vertrag zu erf&uuml;llen, an dem die betroffene Person beteiligt ist, bezieht sich die rechtliche Grundlage auf Art. 6 I </span><span class="s2">lit</span><span class="s2">. b DS-GVO. Das gilt auch f&uuml;r Verarbeitungsvorg&auml;nge, die zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich sind, beispielsweise bei Anfragen zu unseren Produkten oder Dienstleistungen. Hier ist es wichtig, dass die betroffene Person die notwendigen Daten zur Verf&uuml;gung stellt, damit wir ihre Anfragen oder Bestellungen ordnungsgem&auml;&szlig; bearbeiten k&ouml;nnen.</span></p>
<p><span class="s2">Des Weiteren gibt es Situationen, in denen unser Unternehmen aufgrund gesetzlicher Verpflichtungen personenbezogene Daten verarbeiten muss, etwa um steuerliche Anforderungen zu erf&uuml;llen. In solchen F&auml;llen greift Art. 6 I </span><span class="s2">lit</span><span class="s2">. c DS-GVO. Diese Vorschrift stellt sicher, dass wir unseren rechtlichen Verpflichtungen nachkommen, um die Einhaltung der geltenden Gesetze zu gew&auml;hrleisten.</span></p>
<p><span class="s2">Es k&ouml;nnen auch au&szlig;ergew&ouml;hnliche Umst&auml;nde auftreten, in denen die Verarbeitung personenbezogener Daten erforderlich ist, um lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen Person zu sch&uuml;tzen. Ein Beispiel w&auml;re, wenn jemand in unserem Unternehmen medizinische Hilfe ben&ouml;tigt und wir daher Informationen wie den Namen, das Alter und die Krankenkassendaten an medizinisches Personal weitergeben m&uuml;ssen. In solchen F&auml;llen w&uuml;rde die </span><span class="s2">Verarbeitung auf Art. 6 I </span><span class="s2">lit</span><span class="s2">. d DS-GVO beruhen, um sicherzustellen, dass wir in Notfallsituationen angemessen reagieren k&ouml;nnen.</span></p>
<p><span class="s2">Schlie&szlig;lich k&ouml;nnten einige Verarbeitungsvorg&auml;nge auf Art. 6 I </span><span class="s2">lit</span><span class="s2">. f DS-GVO basieren, die es uns erlauben, personenbezogene Daten zu verarbeiten, wenn es erforderlich ist, um ein berechtigtes Interesse unseres Unternehmens oder eines Dritten zu wahren. Hierbei ist jedoch sicherzustellen, dass die Interessen, Grundrechte und Grundfreiheiten der betroffenen Person nicht &uuml;berwiegen. Diese Regelung erm&ouml;glicht es uns, bestimmte Verarbeitungen durchzuf&uuml;hren, die nicht unter die vorher genannten Kategorien fallen, jedoch im Einklang mit dem Datenschutz stehen. Insbesondere hat der Europ&auml;ische Gesetzgeber klargestellt, dass ein berechtigtes Interesse vorliegen kann, wenn die betroffene Person Kunde des Verantwortlichen ist, was in Erw&auml;gungsgrund 47 Satz 2 DS-GVO angesprochen wird.</span></p>
<p>&nbsp;</p>
<p><span class="s2">11. Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder einem Dritten verfolgt werden</span></p>
<p><span class="s2">Die Verarbeitung personenbezogener Daten, die auf Artikel 6 I </span><span class="s2">lit</span><span class="s2">. f der Datenschutz-Grundverordnung (DS-GVO) basiert, erfolgt in unserem berechtigten Interesse zur Unterst&uuml;tzung unserer Gesch&auml;ftst&auml;tigkeit. Dieses Interesse dient dem Wohlergehen aller Mitarbeiter und Anteilseigner und umfasst alle Ma&szlig;nahmen, die zur Optimierung und Stabilit&auml;t unserer Unternehmensabl&auml;ufe notwendig sind. Ziel ist es, ein gesundes und produktives Arbeitsumfeld zu f&ouml;rdern und die Interessen aller Beteiligten in Einklang zu bringen.</span></p>
<p>&nbsp;</p>
<p><span class="s2">12. Dauer, f&uuml;r die die personenbezogenen Daten gespeichert werden</span></p>
<p><span class="s2">Das Kriterium f&uuml;r die Speicherung personenbezogener Daten basiert auf den jeweiligen gesetzlichen Aufbewahrungsfristen. Nach Ablauf dieser Fristen werden die entsprechenden Daten routinem&auml;&szlig;ig gel&ouml;scht, es sei denn, sie sind weiterhin f&uuml;r die Erf&uuml;llung von Vertr&auml;gen oder deren Anbahnung notwendig. Diese Vorgehensweise gew&auml;hrleistet, dass wir nur die Daten aufbewahren, die f&uuml;r unsere gesch&auml;ftlichen und rechtlichen Verpflichtungen erforderlich sind, und gleichzeitig die datenschutzrechtlichen Vorgaben einhalten.</span></p>
<p>&nbsp;</p>
<p><span class="s2">13. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen Daten; Erforderlichkeit f&uuml;r den Vertragsabschluss; Verpflichtung der betroffenen Person, die personenbezogenen Daten bereitzustellen; m&ouml;gliche Folgen der Nichtbereitstellung</span></p>
<p><span class="s2">Wir m&ouml;chten Sie darauf hinweisen, dass die Bereitstellung personenbezogener Daten in bestimmten Situationen entweder gesetzlich vorgeschrieben ist, beispielsweise im Rahmen von steuerlichen Anforderungen, oder sich aus vertraglichen Vereinbarungen ergibt. In vielen F&auml;llen ist es notwendig, dass eine betroffene Person uns bestimmte personenbezogene Informationen zur Verf&uuml;gung stellt, um einen Vertrag erfolgreich abzuschlie&szlig;en. Fehlen diese Informationen, kann das dazu f&uuml;hren, dass der Vertrag nicht zustande kommt. Vor der Bereitstellung solcher Daten ist es ratsam, dass die betroffene Person mit einem unserer Mitarbeiter spricht. Dieser kann dann im Einzelfall kl&auml;ren, ob die Bereitstellung der personenbezogenen Daten rechtlich oder vertraglich erforderlich ist, ob eine Pflicht zur Bereitstellung besteht und welche Konsequenzen die Nichterf&uuml;llung dieser Pflicht haben k&ouml;nnte.</span></p>
<p><span class="s2">14. Bestehen einer automatisierten Entscheidungsfindung</span></p>
<p><span class="s2">Die </span><span class="s2">Concrete</span><span class="s2"> Gold Academy setzt sich f&uuml;r verantwortungsbewussten Umgang mit Daten ein und verzichtet auf automatisierte Entscheidungsfindungen oder </span><span class="s2">Profiling</span><span class="s2">. Dies bedeutet, dass keine Entscheidungen &uuml;ber betroffene Personen ausschlie&szlig;lich auf automatisierten Verarbeitungsprozessen basieren, die eine rechtliche Wirkung entfalten oder diese in &auml;hnlicher Weise erheblich beeinflussen. Die Academy strebt danach, Transparenz und Menschlichkeit in der Datenverarbeitung zu gew&auml;hrleisten, indem sie individuelle Entscheidungen und Interaktionen priorisiert.</span></p>
        </div>
    );
}

export default TermsOfService;