import React from 'react';

function PrivacyPolicy() {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Privacy Policy/Datenschutzerklärung</h1>
            <address>
                CONCRETE GOLD ACADEMY<br />
                LOUI SERA EINZELUNTERNEHMEN<br />
                KONTAKT<br />
                Telefon: +49 1511 2019526<br />
                E-Mail: contact@cg-academy.online
            </address>
            <h2>Vertreten durch</h2>
            <address>
                Loui Sera<br />
                Rabet 22<br />
                04315 Leipzig<br />
            </address>
            <h2>Haftungsausschluss</h2>
            <h3>Haftung für Inhalte</h3>
            <p>
                Unsere Seiteninhalte wurden mit größter Sorgfalt erstellt, dennoch können wir keine Garantie für ihre Richtigkeit, Vollständigkeit oder Aktualität übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte nach den allgemeinen Gesetzen verantwortlich. Laut §§ 8 bis 10 TMG besteht jedoch keine Verpflichtung, übermittelte oder gespeicherte fremde Informationen zu überwachen oder aktiv nach möglichen rechtswidrigen Inhalten zu suchen. Eine Haftung erfolgt erst bei Kenntnis konkreter Rechtsverstöße, die wir bei Bekanntwerden umgehend beheben werden.
            </p>
            <h3>Haftung für Links</h3>
            <p>
                Unser Angebot enthält Links zu externen Webseiten, deren Inhalte außerhalb unserer Kontrolle liegen. Daher übernehmen wir keine Haftung für diese Inhalte; die Verantwortung liegt bei den jeweiligen Betreibern. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung überprüft und es waren keine Rechtsverstöße erkennbar. Ohne konkrete Hinweise auf Rechtsverstöße ist eine kontinuierliche Prüfung der Inhalte jedoch nicht zumutbar. Sollten Rechtsverletzungen bekannt werden, entfernen wir die entsprechenden Links unverzüglich.
            </p>
            <h3>Urheberrecht</h3>
            <p>
                Die Inhalte und Werke, die vom Betreiber dieser Seite erstellt wurden, sind durch das deutsche Urheberrecht geschützt. Jegliche Vervielfältigung, Bearbeitung oder Verbreitung außerhalb der gesetzlich erlaubten Rahmenbedingungen erfordert die schriftliche Zustimmung des jeweiligen Urhebers. Downloads und Kopien sind ausschließlich für den privaten, nicht kommerziellen Gebrauch zulässig. Inhalte, die nicht vom Betreiber stammen, werden entsprechend als Drittinhalte gekennzeichnet und urheberrechtlich respektiert. Sollten Sie dennoch eine Urheberrechtsverletzung entdecken, bitten wir um Hinweis, damit solche Inhalte umgehend entfernt werden können.
            </p>
            <h3>Datenschutz</h3>
            <p>
                Die Nutzung unserer Website ist normalerweise ohne die Angabe persönlicher Daten möglich. Wo Daten wie Name oder E-Mail-Adresse abgefragt werden, geschieht dies freiwillig und nur mit Ihrer Zustimmung werden diese Daten an Dritte weitergegeben. Es ist zu beachten, dass die Datenübertragung im Internet nicht vollständig vor unbefugtem Zugriff geschützt ist. Der Nutzung der im Impressum veröffentlichten Kontaktdaten für ungewollte Werbung wird widersprochen. Die Betreiber behalten sich rechtliche Schritte im Fall unerwünschter Werbung, etwa durch Spam, ausdrücklich vor.
            </p>
        </div>
    );
}

export default PrivacyPolicy;