import React, { useState, useEffect } from 'react';
import './ComingSoonPage.css';
// Ensure Font Awesome is included in your project

function ComingSoonPage() {
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState(''); // 'success' or 'error'
    const [isChecked, setIsChecked] = useState(false); // New state for checkbox

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Send form data to API
        const response = await fetch("https://w4pazw0hg1.execute-api.eu-central-1.amazonaws.com/dev/comingsoonusers", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email }), 
        });

        if (response.ok) {
          setAlertMessage("Form submitted successfully!");
          setAlertType("success");
          setShowForm(false); // Close the popup on successful submission
        } else if (response.status === 400) { // Handle 400 response
          setAlertMessage("User with this email and name combination already exists.");
          setAlertType("error");
        } else {
          setAlertMessage("Error submitting form.");
          setAlertType("error");
        }

        // Reset alert message after 3 seconds
        setTimeout(() => {
            setAlertMessage('');
            setAlertType('');
        }, 3000); // Adjust the duration as needed
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const closePopup = () => {
        setShowForm(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const popup = document.querySelector('.form-popup');
            if (popup && !popup.contains(event.target)) {
                closePopup();
            }
        };

        if (showForm) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showForm]);

    return (
        <div className="coming-soon-container">
            <div className={`coming-soon-content ${showForm ? 'blurred' : ''}`}>
                <h1>Something Great is coming!!</h1>
                <p>We're building something big. Prepare for real estate success with our bootcamp.</p>
                <button className="notify-button" onClick={() => setShowForm(true)}>
                    <i className="fas fa-bell"></i> {/* Font Awesome bell icon */}
                    Get Notified!!
                </button>
            </div>
            <div className={`circle-image ${showForm ? 'blurred' : ''}`}>
                <div className="company-name">CONCRETE GOLD ACADEMY</div> {/* Company name below the logo */}
            </div>
            {showForm && (
                <div className="form-popup">
                    <button className="close-button" onClick={closePopup}>×</button>
                    <h2>Please enter your details to get more details and amazing offers!!</h2>
                    <form onSubmit={handleSubmit}>
                        <input 
                            type="text" 
                            placeholder="Enter your name" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                        />
                        <input 
                            type="email" 
                            placeholder="Enter your email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                        <div className="disclaimer">
                            <input 
                                type="checkbox" 
                                checked={isChecked} 
                                onChange={(e) => setIsChecked(e.target.checked)} 
                            />
                            <span>
                                By using this site, you accept our 
                                <a href="/privacypolicy" target="_blank" rel="noopener noreferrer"> Privacy Policy </a> 
                                and 
                                <a href="/imprint" target="_blank" rel="noopener noreferrer"> Imprint</a>.
                            </span>
                        </div>
                        <button type="submit" className={!isChecked ? 'disabled' : ''} disabled={!isChecked}>Submit</button>
                    </form>
                </div>
            )}
            {alertMessage && (
                <div className={`alert-popup ${alertType}`}>
                    {alertMessage}
                </div>
            )}
        </div>
    );
}

export default ComingSoonPage;
